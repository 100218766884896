.keyboard {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: .7rem;
  width: 20.6rem; /* 7 times item width plus padding */
  padding: .5rem;
  background-color: var(--light-grey);
  border-radius: 0.3rem;
  box-shadow: 0px 1px 5px var(--mid-grey);
}