.game-page {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 30px;
  height: 100%;
  padding: 30px
}

.game-page .gallows-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 400px;
  height: 100%;
}

.game-page .result-wrapper {
  max-height: 500px;
}

.game-page .keyboard-wrapper {
  margin-top: 30px;
}

.game-page .game-ended {
  grid-column: 1 /3;
  grid-row: 1 / 3;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.game-page .game-ended.success p {
  color: var(--medium-green);
}

.game-page .game-ended.failure p {
  color: var(--medium-red);
}

.game-page .game-ended .title {
  font-size: 2.5em;
  margin-bottom: 20px;
}

.game-page .game-ended .message {
  font-size: 2em;
}

.game-page .game-ended button {
  margin-top: 30px;
}