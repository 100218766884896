.gallows-component {
  position: relative;
  width: 100%;
  height: 100%;
}

.gallows-component span {
  position: absolute;
  display: block;
  background-color: var(--medium-brown);
}

.gallows-component .foot {
  min-width: 100%;
  min-height: 20px;
  bottom: 0;
}

.gallows-component .root {
  min-width: 20px;
  min-height: 100%;
  left: 15%;
}

.gallows-component .arm {
  min-width: 70%;
  min-height: 20px;
  left: 15%;
}

.gallows-component .crossbar {
  min-width: 30%;
  min-height: 20px;
  top: 8%;
  left: 13%;
  transform: rotateZ(135deg);
}