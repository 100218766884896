.category-menu {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.category-menu .categories {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 20px;
  max-width: 800px;
  width: 90%;
  margin: 0 auto;
  padding: 20px;
  border: 1px solid var(--light-grey);
  border-radius: 0.3rem;
  box-shadow: 0px 1px 5px var(--mid-grey);
}

.category-menu .category-button.selected {
  background-color: var(--dark-blue);
}

.category-menu .start-button {
  display: block;
  margin: 20px auto;
}