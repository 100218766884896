html, body, div, span, p, ul, li, input, button {
  margin: 0;
  padding: 0;
  color: var(--mid-grey);
  font-family: DM Sans, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
  list-style: none;
}

:root {
  --normal-white: #fff;
  --normal-black: #000;
  --medium-brown: #a52a2a;
  --medium-green: rgb(0, 182, 0);
  --light-blue: #E4F3F7;
  --medium-blue: #009BC1;
  --dark-blue: #006d8b;
  --medium-red: #FF4C50;
  --medium-yellow: #FFCC1E;
  --very-light-grey: #f3f6f6;
  --light-grey: #ececec;
  --mid-grey: #6c757d;
  --dark-grey: #15312e;
}