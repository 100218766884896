.keyboard-letter {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 2.2rem;
  height: 2.2rem;
  font-size: 1.2rem;
  border: 1px solid var(--medium-blue);
  background-color: var(--light-blue);
  text-transform: uppercase;
}

.keyboard-letter:hover {
  cursor: pointer;
  box-shadow: 0px 1px 5px var(--mid-grey);
}

.keyboard-letter.selected {
  opacity: 0.3;
  cursor: not-allowed;
}